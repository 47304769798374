import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/images/etijwaallogo.jpg";
import sim from "../assets/images/esim-ico_small.avif";
import toast from "react-hot-toast";
import countryCodes from "../helpers/countryCodes.json";
import axios from "axios";
import { app_url, environment } from "../config";
import { Link, Navigate, json, useNavigate, useParams } from "react-router-dom";
import NotFoundError from "./errors/NotFoundError";
import Loader from "./loaders/Loader";
import OrderSubmited from "./modals/OrderSubmited";
import PaypalModal from "./payment/PaypalModal";
import Emailsend from "./modals/Emailsend";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const UserdetailForm = () => {
  const allbrandCountryCodes = countryCodes.codes;
  const [paymentmethod, setpaymentmethod] = useState("");
  const [otpsection, setotpsection] = useState(false);
  const [userverification, setuserverification] = useState(false);
  const [discountcode, setdiscountcode] = useState("");
  const [promocodeid, setpromocodeid] = useState(null);
  const [email, setemail] = useState("");
  const [otpcode, setotpcode] = useState("");
  const [country, setcountry] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [address, setaddress] = useState("");
  // const [company, setcompany] = useState("")
  const [city, setcity] = useState("");
  const [postalcode, setpostalcode] = useState("");
  const [countrycode, setcountrycode] = useState("");
  const [phone, setphone] = useState("");
  const [sessionId, setsessionId] = useState("");
  const [isSession, setisSession] = useState(false);
  const [sessiondata, setsessiondata] = useState("");
  const [isLoader, setisLoader] = useState(true);
  const [btnloader, setbtnloader] = useState(false);
  const [formbtnloader, setformbtnloader] = useState(false);
  const [pkgprice, setpkgprice] = useState("");
  const [formSubmitted, setformSubmitted] = useState("");
  const [CountryName, setCountryName] = useState("");
  const [userip, setuserip] = useState(null);
  const [paypalinfo, setpaypalinfo] = useState(null);
  const [formbtntext, setformbtntext] = useState("Continue to Payment");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [amountPayable, setAmountPayable] = useState(null);
  const [needTransaction, setNeedTransaction] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const apiCalledOnce = useRef(false);

  // email verification
  const verifyemail = async (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("Enter Your Email");
    } else {
      setbtnloader(true);
      await axios
        .post(`${app_url}/api/shopSession/sendOtpByEmailId?emailId=${email}`)
        .then((response) => {
          if (response.data.success) {
            setotpsection(true);
            setbtnloader(false);
            document.querySelector(".emailsend").click();
          } else {
            // console.log(response.data.message);
            setbtnloader(false);
          }
        })
        .catch((err) => {
          setisLoader(false);
          setbtnloader(false);
          if (err.response) {
            if (err.response.status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (err.response.status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error(
                err.response.data.message || "An unexpected error occurred."
              );
            }
          } else {
            toast.error(
              "Network error. Please check your internet connection."
            );
          }
        });
    }
  };

  // console.log(promocodeid, "promo code id");

  const submitdiscont = async (e) => {
    e.preventDefault();
    if (discountcode === "") {
      toast.error("Enter Your Code");
    } else {
      await axios
        .get(
          `${app_url}/api/promo/getPromoByPromoCode_v2?promoCode=${discountcode}&pId=${sessionId}`
        )
        .then((response) => {
          if (response.data.success) {
            setpromocodeid(response?.data?.data?.id);
            setDiscountPercentage(response?.data?.data?.discount_percentage);
            setDiscountAmount(response?.data?.data?.discount_amount);
            setAmountPayable(response?.data?.data?.amount_payable);
            setNeedTransaction(response?.data?.data?.need_transaction);
            // setpkgprice(pkgprice - (pkgprice * (response.data.data[0].discountAmountPercent / 100)))
            // if (pkgprice - response.data.data[0].discountAmountPercent <= 0) {
            //   setpkgprice(0);
            // } else {
            //   setpkgprice(
            //     pkgprice - response.data.data[0].discountAmountPercent
            //   );
            // }
          }
          // else {
          //   console.log(response.error);
          // }
        })
        // .catch((error) => {
        //   // toast.error(error)

        //   toast.error(error.response.data.message);
        //   console.error(error.response.data.message);
        //   // setisSession(true)
        //   // setloading(false)
        // });
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (err.response.status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error(
                err.response.data.message || "An unexpected error occurred."
              );
            }
          } else {
            toast.error(
              "Network error. Please check your internet connection."
            );
          }
        });
    }
  };

  const verifyotp = async (e) => {
    e.preventDefault();
    if (otpcode === "") {
      toast.error("Check Your Email");
    } else {
      setbtnloader(true);
      await axios
        .get(
          `${app_url}/api/shopSession/matchOtpByEmailAndOtp?emailId=${email}&otp=${otpcode}`
        )
        .then((response) => {
          if (response.data.success) {
            setuserverification(true);
          } else {
            // console.log(response.data.message);
            setbtnloader(false);
          }
        })
        .catch((err) => {
          setisLoader(false);
          if (err.response) {
            if (err.response.status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (err.response.status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error(
                err.response.data.message || "An unexpected error occurred."
              );
            }
          } else {
            toast.error(
              "Network error. Please check your internet connection."
            );
          }
        });
    }
  };

  const changeemail = () => {
    setemail("");
    setotpsection(false);
    setbtnloader(false);
  };

  const phoneno = (e) => {
    const numericValue = e.target.value.replace(/[^-+0-9()]/, "");
    setphone(numericValue);
  };

  const formsubmit = async (e) => {
    e.preventDefault();
    const emptyFields = [];

    if (country === "") {
      emptyFields.push(" Country");
    }
    if (firstname === "") {
      emptyFields.push(" First Name");
    }
    if (lastname === "") {
      emptyFields.push(" Last Name");
    }
    if (address === "") {
      emptyFields.push(" Address");
    }
    if (city === "") {
      emptyFields.push(" City");
    }
    if (postalcode === "") {
      emptyFields.push(" Postal Code");
    }
    if (countrycode === "") {
      emptyFields.push(" Country Code");
    }
    if (phone === "") {
      emptyFields.push(" Phone");
    }
    if (paymentmethod === "") {
      emptyFields.push(" Select Payment Method");
    }
    // if (paymentmethod === 'stripe') {
    //   emptyFields.push(' Only Paypal Available ');
    // }
    if (emptyFields.length > 0) {
      // console.log(`Fill in the following fields : ${emptyFields}`);
      toast.error(`Required: ${emptyFields}`);
    } else if (needTransaction) {
      setformbtnloader(true);
      await axios
        .post(`${app_url}/api/payment/createCustomerAndOrder`, {
          email: email,
          country: country,
          first_name: firstname,
          last_name: lastname,
          postalCode: postalcode,
          mobile: countrycode + phone,
          packageId: sessionId,
          promoCodeId: promocodeid,
          sessionId: id,
          gateway: paymentmethod,
          trackDetails: userip?.data,
          environment: environment,
        })
        .then((response) => {
          if (response.data?.gateway === "paypal") {
            setformbtnloader(false);
            toast.success(response.data?.message);
            setformSubmitted(response.data);
            setpaypalinfo(response);
            document.querySelector(".paypal-modal").click();
          } else if (response.data?.gateway === "tap") {
            setformbtnloader(false);
            toast.success(response.data.message);
            window.location.href = response.data.paymentUrl;
          } else {
            // console.log(response.data.message, "this");
            setformbtnloader(false);
          }
        })
        .catch((error) => {
          setformbtnloader(false);
          toast.error(error.response.data.message);
        });
    } else if (!needTransaction) {
      // if (pkgprice === 0 || pkgprice <= 0) {
      setformbtnloader(true);
      axios
        .post(`${app_url}/api/payment/processOrderwithoutapyment`, {
          email: email,
          country: country,
          first_name: firstname,
          last_name: lastname,
          postalCode: postalcode,
          mobile: countrycode + phone,
          packageId: sessionId,
          promoCodeId: promocodeid,
          sessionId: id,
          trackDetails: userip?.data,
          environment: environment,
        })
        .then((response) => {
          if (response.data.success) {
            document.querySelector(".OrderCompletedModal").click();
            setformbtnloader(false);
          } else {
            setformbtnloader(false);
          }
        })
        .catch((err) => {
          setformbtnloader(false);
          if (err.response) {
            if (err.response.status === 400) {
              toast.error(err.response.data.message || "Bad Request");
            } else if (err.response.status === 500) {
              toast.error(err.response.data.message || "Internal Server Error");
            } else {
              toast.error(
                err.response.data.message || "An unexpected error occurred."
              );
            }
          } else {
            toast.error(
              "Network error. Please check your internet connection."
            );
          }
        });

      // }
    }
  };

  // console.log(pkgprice, "price pkg check");
  // console.log(amountPayable, "price check");

  useEffect(() => {
    axios
      .get(`https://geolocation-db.com/json/`)
      .then((response) => {
        setuserip(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const apigetShopSessionBySessionId = () => {
    axios
      .get(
        `${app_url}/api/shopSession/getShopSessionBySessionId?sessionId=${id}`
      )
      .then((response) => {
        setisLoader(false);
        if (response.data.success) {
          setsessionId(response.data.data[0].packageId);
          setsessiondata(response.data);
          setpkgprice(response.data.data[0].price);
          setCountryName(response.data.country_Region_Global[0].name);
        } else {
          // console.log(response.data.message);
        }
      })
      .catch((err) => {
        setisLoader(false);
        setisSession(true);
        if (err.response) {
          if (err.response.status === 400) {
            toast.error(err.response.data.message || "Bad Request");
          } else if (err.response.status === 401) {
            navigate("/");
          } else if (err.response.status === 500) {
            toast.error(err.response.data.message || "Internal Server Error");
          } else {
            toast.error("An unexpected error occurred.");
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  useEffect(() => {
    if (!apiCalledOnce.current) {
      apiCalledOnce.current = true;
      // console.log(apiCalledOnce, "check api called");
      // console.log("inside useEffect checking apigetShopSessionBySessionId");

      apigetShopSessionBySessionId();
    }
  }, []);

  const formsubmitbtn = () => {
    document.querySelector(".submitform").click();
  };

  useEffect(() => {
    if (!needTransaction) {
      setformbtntext("Process Order");
    }
  }, [submitdiscont]);

  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <OrderSubmited formSubmitted={formSubmitted} />
          {isSession ? (
            <NotFoundError />
          ) : (
            <section className="cart-bg py-5 min-vh-100 ">
              <div className="container">
                <Link to={"https://etijwaal.com/"}>
                  <img src={logo} alt="" className="logo mb-3 "></img>
                </Link>
                <div className="row ">
                  <div className="col-lg-6 pe-lg-5 reverse-after-lg ">
                    <div className="mt-5">
                      {userverification ? (
                        <p className="heading-m text-start mb-0 text-center mt-5">
                          {email}
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="green"
                              className="ms-3 mb-2 bi bi-patch-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                            </svg>
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                      <div>
                        {userverification ? (
                          ""
                        ) : (
                          <>
                            <p className="heading-m text-start mb-0">
                              Verify Your Email to Continue
                            </p>
                            <form onSubmit={verifyemail} className="emailcheck">
                              <input
                                type="email"
                                readOnly={otpsection}
                                placeholder="email"
                                className="form-control me-3"
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                                required
                              />
                              {otpsection ? (
                                <button
                                  className={`btn primary-btn w-50`}
                                  onClick={() => changeemail()}
                                >
                                  Change Email
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className={`btn primary-btn ${
                                    btnloader ? "w-50" : "w-50"
                                  }`}
                                  disabled={btnloader}
                                >
                                  Verify Email{" "}
                                  {btnloader ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    ""
                                  )}{" "}
                                </button>
                              )}
                            </form>
                            {otpsection ? (
                              <form onSubmit={verifyotp} className="emailcheck">
                                <input
                                  type="text"
                                  placeholder="Verification Code"
                                  className="form-control me-3"
                                  value={otpcode}
                                  onChange={(e) => setotpcode(e.target.value)}
                                />
                                <button
                                  className="btn primary-btn"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </form>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        <form
                          className="mt-5 billing-add"
                          onSubmit={formsubmit}
                        >
                          <p className="heading-m text-start ">
                            Billing address
                          </p>
                          <select
                            name="country"
                            id=""
                            className="form-select mt-3"
                            value={country}
                            onChange={(e) => setcountry(e.target.value)}
                          >
                            <option value="" hidden>
                              Country
                            </option>
                            {Object.keys(allbrandCountryCodes).map(
                              (objkey, index) => {
                                return (
                                  <option key={index} value={[objkey]}>
                                    {[objkey]}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                name="firstname"
                                value={firstname}
                                onChange={(e) => setfirstname(e.target.value)}
                                maxLength={50}
                              />
                              <p className="inp-length">
                                {firstname.length}/50
                              </p>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                name="lastname"
                                value={lastname}
                                onChange={(e) => setlastname(e.target.value)}
                                maxLength={50}
                              />
                              <p className="inp-length">{lastname.length}/50</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                name="address"
                                value={address}
                                onChange={(e) => setaddress(e.target.value)}
                                maxLength={250}
                              />
                              <p className="inp-length">{address.length}/250</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="city"
                                value={city}
                                onChange={(e) => setcity(e.target.value)}
                                maxLength={30}
                              />
                              <p className="inp-length">{city.length}/30</p>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Postal Code"
                                name="postalcode"
                                value={postalcode}
                                onChange={(e) => setpostalcode(e.target.value)}
                                maxLength={15}
                              />
                              <p className="inp-length">
                                {postalcode.length}/15
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <select
                                id=""
                                className="form-select mt-3"
                                name="countrycode"
                                value={countrycode}
                                onChange={(e) => setcountrycode(e.target.value)}
                              >
                                <option value="" hidden>
                                  Country Code
                                </option>
                                {Object.keys(allbrandCountryCodes).map(
                                  (objkey, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={allbrandCountryCodes[objkey]}
                                      >{`${[objkey]} +${
                                        allbrandCountryCodes[objkey]
                                      }`}</option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                name="phone"
                                value={phone}
                                onChange={phoneno}
                                maxLength={20}
                              />
                              <p className="inp-length">{phone.length}/20</p>
                            </div>
                          </div>
                          <div className="card position-relative text-center  mt-5">
                            <div className="expresscheckout-text">
                              <p>Payment Method</p>
                            </div>
                            <div className="card-body">
                              <div className="row justify-content-center expresscheckout">
                                <div className="col-md-8 mt-2 px-md-2 px-1">
                                  <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="tap">
                                      <input
                                        type="radio"
                                        name="expresscheckout"
                                        id="tap"
                                        value="tap"
                                        className="my-1 me-2 form-check-input"
                                        checked={paymentmethod === "tap"}
                                        onChange={(e) =>
                                          setpaymentmethod(e.target.value)
                                        }
                                      />
                                      Debit/Credit
                                    </label>
                                    <label htmlFor="paypalpay">
                                      <input
                                        type="radio"
                                        name="expresscheckout"
                                        id="paypalpay"
                                        value="paypal"
                                        className="my-1 me-2 form-check-input"
                                        checked={paymentmethod === "paypal"}
                                        onChange={(e) =>
                                          setpaymentmethod(e.target.value)
                                        }
                                      />
                                      Paypal
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {userverification ? (
                            <div className="text-end">
                              <button
                                type="submit"
                                className={`btn primary-btn mt-3 text-right position-absolute opacity-0 top-0 p-0 accordion  submitform ${
                                  formbtnloader ? "w-50" : ""
                                }`}
                              >
                                {formbtnloader ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}{" "}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 px-lg-5  my-lg-0 my-5">
                    <div className="card  backoutline rounded-3  border-0 ">
                      <div className="card-body">
                        <div className="purchase-product-detail">
                          <div className="d-md-flex ">
                            <div className="purchase-product-icon mb-3">
                              <img src={sim} alt="" />
                            </div>
                            <div className="d-flex ms-md-3 align-items-center justify-content-between w-100">
                              <div>
                                <p className="heading-m text-start">
                                  {CountryName}
                                </p>
                                <p className="para m-0">
                                  {sessiondata?.data[0].day} days and{" "}
                                  {sessiondata?.data[0].includeData} data
                                </p>
                              </div>
                              <div>
                                <p className="heading-m fw-bold ">
                                  <span>US</span>$
                                  {sessiondata.data[0].price.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <form
                            onSubmit={submitdiscont}
                            className="emailcheck my-2"
                          >
                            <input
                              type="text"
                              readOnly={promocodeid}
                              placeholder="Discount Code"
                              className="form-control me-3 py-2"
                              value={discountcode}
                              onChange={(e) => setdiscountcode(e.target.value)}
                            />
                            {promocodeid === null ? (
                              <button
                                className="btn primary-btn "
                                type="submit"
                              >
                                Apply
                              </button>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="46"
                                height="46"
                                fill="currentColor"
                                className="bi bi-check2-all"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                              </svg>
                            )}
                          </form>
                          {promocodeid !== null && (
                            <>
                              <hr />
                              <div className="emailcheck justify-content-between">
                                <p
                                  className="heading-m text-start "
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Discount Percentage:
                                </p>
                                <p className="heading-m fw-bolder ">
                                  <span>{discountPercentage}</span>
                                </p>
                              </div>
                              {/* <hr /> */}
                              <div className="emailcheck justify-content-between mt-0">
                                <p
                                  className="heading-m text-start"
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Discount Amount:
                                </p>
                                <p className="heading-m fw-bolder ">
                                  <span>US{discountAmount}</span>
                                </p>
                              </div>
                            </>
                          )}
                          <hr />
                          <div className="emailcheck justify-content-between">
                            <p
                              className="heading-m text-start"
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Total
                            </p>
                            <p className="heading-m fw-bolder ">
                              <span>US</span>
                              {amountPayable === null
                                ? `$${sessiondata.data[0].price.toFixed(2)}`
                                : amountPayable}
                            </p>
                          </div>

                          {userverification ? (
                            <div className="text-end mt-5">
                              <button
                                type="submit"
                                className={`btn primary-btn mt-3 text-right ${
                                  formbtnloader ? "w-50" : ""
                                }`}
                                disabled={formbtnloader}
                                onClick={() => formsubmitbtn()}
                              >
                                {formbtntext}
                                {formbtnloader ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}{" "}
                              </button>
                            </div>
                          ) : (
                            <p className="mt-3 fs-6">
                              Please confirm your email to proceed with your
                              payment securely.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <PaypalModal paypalinfo={paypalinfo} />
          <Emailsend changeemail={changeemail} />
        </>
      )}
    </>
  );
};

export default UserdetailForm;
